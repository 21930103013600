import { render, staticRenderFns } from "./IntroductionVertical.vue?vue&type=template&id=200259f4&scoped=true&"
import script from "./IntroductionVertical.vue?vue&type=script&lang=js&"
export * from "./IntroductionVertical.vue?vue&type=script&lang=js&"
import style0 from "./IntroductionVertical.vue?vue&type=style&index=0&id=200259f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200259f4",
  null
  
)

export default component.exports